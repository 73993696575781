import React, { useEffect, useState } from "react";
import "../css/home.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import UserService from "../../services/UserService";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetails, getNotification } from "../../Redux/Action/action";
import Loader from "react-js-loader";
import { tabItem } from "../Agreement_Data/tabItem";
import Popup from "./Popup";
import bailImage from "../.././assets/bail.jpg"
import antiBailImage from "../.././assets/anitibail.jpg"
import VideoPopup from "./VideoPopup";
import vdo from '../../assets/Demo1.mp4'



const { REACT_APP_CREATE_AGREEMENT_API, REACT_APP_REDIRECT_URI } = process.env;
const Home = () => {
  const state = useSelector((state) => state.stateReducer);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation()
  const [descriptionValue, setDescriptionValue] = useState(false);
  const [loader, setLoader] = useState(false);

  const [tabItemState, setTabItemState] = useState([])
  const [searchQuery, setSearchQuery] = useState('');
  const [popup, setPopup] = useState(false)


  useEffect(() => {
    setTabItemState(tabItem)
    // if (UserService.isLoggedIn()) checkTimeInterval()
    if (UserService.isLoggedIn()) getPlanDetails()
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])


  const checkTimeInterval = () => {
    const ONE_HOUR = 60 * 60 * 1000; // 1 hour in milliseconds
    const lastApiCallTime = localStorage.getItem(UserService.getUserId() + "lastApiCallTime");

    const now = Date.now();

    const status = JSON.parse(localStorage.getItem(UserService.getUserId() + "StatusKey")) 
    if (status.userStatusFlag) setDescriptionValue(true)
    else setDescriptionValue(false)

    // localStorage.setItem(userID + "StatusKey", JSON.stringify(tempObj))
    if (!lastApiCallTime || now - lastApiCallTime >= ONE_HOUR) {
      // Call the API and update the timestamp
      getPlanDetails()
        .then(response => {
          // console.log("API response:", response);
          // Save the current timestamp
          localStorage.setItem(UserService.getUserId() + "lastApiCallTime", now.toString());
        })
        .catch(error => {
          console.error("Error calling the API:", error);
        });
    } else {
      console.log("Skipping API call; last call was less than an hour ago.");
    }
  }


  const getPlanDetails = async (value) => {
    setLoader(true);
    try {
      // if (!value === "noRefresh") setLoaderCredit(true); // wanna keep the form datas so using this
      // setLoader(true)
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        access_token: UserService.getToken(),
      };
      const getResponse = await axios.post(
        `${REACT_APP_CREATE_AGREEMENT_API}/get_user_status?keycloak_user_id=${UserService.getUserId()}`,
        null,
        { headers: headers }
      );
      if (getResponse.status === 200) {
        dispatch(getUserDetails(getResponse.data));
        if (getResponse.data.promotion || getResponse.data.user_credit_summary) {
          setDescriptionValue(true);
          //set cache
          const time = new Date()
          const userID = UserService.getUserId()
          const tempObj = {
            userStatusFlag: true,
            userStatusTime: time,
            userStatusObj: getResponse.data
          }
          localStorage.setItem(userID + "StatusKey", JSON.stringify(tempObj))
        }
        setLoader(false);
      }
    } catch (err) {
      console.log("Error", err);
      setLoader(false);
      if (err.response.status === 400) {
        dispatch(getUserDetails(err.response.data.detail));
        const time = new Date()
        const userID = UserService.getUserId()
        const tempObj = {
          userStatusFlag: false,
          userStatusTime: time,
          userStatusObj: err.response.data.detail
        }
        localStorage.setItem(userID + "StatusKey", JSON.stringify(tempObj))
      } else if (err.response && err.response.status === 401) {
        dispatch(
          getNotification({
            message: "Session expired! Please log in again",
            type: "default",
          })
        );
        setTimeout(() => {
          UserService.doLogin({ redirectUri: `${REACT_APP_REDIRECT_URI}/app` });
        }, 3000);
      } else {
        dispatch(
          getNotification({
            message:
              "You have encountered an error. Please try after some time.",
            type: "default",
          })
        );
      }
    }
    setLoader(false);
  };



  return (
    <div className="flex items-center justify-center flex-col bg-[#F9F9F9]">
      {
        loader ?
          <div className="m-8 my-20 max-w-[400px] mx-auto">
            <div className="mb-8">
              <div style={{ marginTop: "0vh" }} className="md:col-span-2 md:mt-0">
                <Loader
                  type="bubble-loop"
                  bgColor={"#000000"}
                  title={"Loading...."}
                  color={"#000000"}
                  size={100}
                />
              </div>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
            </div>
          </div>
          :
          <div className="container-fluid mt-4">
            <div className="row">
              <div className="lg:w-full mb-3">
                <div className="row rounded-3xl h-full bg-white flex-row justify-between p-3">
                  <div className="col-sm">
                    <h2 className="text-gray-900 text-lg title-font font-medium flex">Welcome!
                      {/* <img className="mx-3" src={wave} alt="wave" /> */}
                    </h2>
                    <p className="leading-relaxed text-base"> Drafting aid for criminal applications suitable for Indian courts.
                    </p>
                  </div>
                  <div className="col-sm flex-shrink-0 flex inline-flex items-center justify-content-end space-x-4 mr-5">
                    <VideoPopup videoSrc={vdo} />
                  </div>
                  {popup && <Popup onConfirm={() => {
                    // Clear local storage
                    localStorage.clear();
                    // Reload the page
                    setPopup(false)
                    window.location.reload();
                  }} onCancel={() => setPopup(false)} text={"This will erase all inputs and current sessions for all Scripters. Are you sure you want to proceed?"} proceed={true} />}
                </div>
              </div>
              <div className="row mt-3">
                {tabItemState &&
                  tabItemState.map((ele, ind) => (
                    <div key={ind} className="col-sm my-4">
                      <div className="blog-card alt">
                        <div className="meta">
                          <div className="photo" style={ind === 0 ? { backgroundImage: `url(${bailImage})` } : { backgroundImage: `url(${antiBailImage})` }}></div>
                        </div>
                        <div className="description">
                          <h1>{ele.name}</h1>
                          <p className="h-20">{ele.description1}</p>
                          <p className="read-more">
                            <button
                              onClick={() => {
                                if (!UserService.isLoggedIn() && ele.disabled) {
                                  UserService.doLogin({
                                    redirectUri: REACT_APP_REDIRECT_URI + "/app",
                                  });
                                } else if (UserService.isLoggedIn() && !descriptionValue) {
                                  if (!ele.paid) {
                                    navigate(ele.to);
                                  }
                                  else {
                                    setTimeout(() => {
                                      navigate("/pricing")
                                    }, 200)
                                  }
                                }
                                else navigate(ele.to);
                              }}
                              className="mt-3 my-4 px-3 py-2 text-lg btn btn-outline-primary">Draft &#x2192;</button>
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
      }
      <br />
      <br />
    </div >
  );
};

export default Home;